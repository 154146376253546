import { createApp } from 'vue'

import Antd from 'ant-design-vue'
import App from './App.vue'
import 'ant-design-vue/dist/reset.css'

import router from './router'

import * as AntdIconsPlugin from '@ant-design/icons-vue'

const app = createApp(App)

app.use(Antd)
app.use(router)
const icons = AntdIconsPlugin
for(const i in icons){
    app.component(i,icons[i])
}

app.mount('#app')